import moment from "moment";
import {
  EMPLOYER_FORM_ID,
  FAQ_FORM_ID,
  JOB_DESCRIPTION_FORM_ID,
  REWARD_FORM_ID,
} from "../forms";

const getFormattedDate = (dateToConvert: any) => {
  const formattedDate = moment(dateToConvert).format("DD MMMM, YYYY");

  return formattedDate;
};

const getStats = (stats, field) => {
  const updatedStats = stats.map((stat) => {
    if (stat["admin_edits"] >= stat[field]) {
      return stat["admin_edits"];
    } else if (stat["admin_edits"] < stat[field]) {
      return stat[field];
    }

    return 0;
  });

  return updatedStats;
};

const parse = (response: any) => {
  const contest = response.formData;

  const parsedContestDetails: any = {};

  const currentDate = new Date().getTime();

  const postedDate = new Date(response["createdOn"]).getTime();

  const postedDuration = Math.floor(
    (currentDate - postedDate) / (1000 * 24 * 60 * 60)
  );

  parsedContestDetails.id = response.id;
  parsedContestDetails.contestId = contest.contestId;

  parsedContestDetails.jobPostedDuration = `${
    postedDuration === 0 ? "Today" : `${postedDuration}d ago`
  }`;

  // // Card Details
  // parsedContestDetails.badge = contest["select_badge"];
  parsedContestDetails.employmentType = contest["cardTaglines"];
  parsedContestDetails.jobTitle = contest["contestTitle"];
  parsedContestDetails.cashReward = `₹${contest["bounty"].toLocaleString(
    "en-IN"
  )}`;
  parsedContestDetails.buttonText = contest["buttonText"];
  parsedContestDetails.company =
    response[EMPLOYER_FORM_ID][0]["formData"]["employerName"];
  parsedContestDetails.experience =
    contest["experienceFrom"] && contest["experienceTo"]
      ? `${contest["experienceFrom"]} to ${contest["experienceTo"]} yrs`
      : null;

  parsedContestDetails.tools = contest["technicalSkills"].join(", ");
  parsedContestDetails.noticePeriod = `${contest["requiredNoticePeriod"]} ${
    contest["requiredNoticePeriod"] > 1 ? "days" : "day"
  } Of Notice Period`;
  parsedContestDetails.locations = contest["locations"].join(", ");
  parsedContestDetails.interviewDays = contest["driveType"];
  parsedContestDetails.positions = `${contest["numberOfPositions"]} Positions`;
  const budgetUnit = contest["denomination"] === "lakhs" ? "L PA" : "K PA";
  parsedContestDetails.ctc = `${contest["budgetCtcFrom"]}${budgetUnit} to ${contest["budgetCtcTo"]}${budgetUnit}`;

  parsedContestDetails.skills = contest["skills"].join(", ");
  parsedContestDetails.degree = contest["qualifications"].join(", ");
  parsedContestDetails.tags = contest["tags"].join(", ");
  parsedContestDetails.domains = contest["domain"]
    ? contest["domain"].join(", ")
    : null;

  // //   parsedContestDetails.iconsToShow = contest["icon_customization"];
  // //   parsedContestDetails.iconStatus =
  // //     recruiterId > 0 ? contest[`recruiter_${recruiterId}_status`] : "";

  // Timer
  parsedContestDetails.endDate = new Date(contest["endDate"]);

  // // Quota
  parsedContestDetails.bonus = `₹${Number(contest["bonus"]).toLocaleString(
    "en-IN"
  )}`;
  parsedContestDetails.quota = contest["numberOfRecruitersToGetBountyAmount"];

  // //Timeline
  const timelineData: any = [];
  timelineData.push({
    date: getFormattedDate(contest["startDate"]),
    event: "Start",
  });
  contest["dataGrid"].map((round: any) => {
    timelineData.push({
      date: round["selectRound1Date1"]
        ? getFormattedDate(round["selectRound1Date1"])
        : null,
      event: round["roundName"],
      isActiveRound: round["is_active_round"],
    });
  });
  timelineData.push({
    date: getFormattedDate(contest["endDate"]),
    event: "End",
  });
  parsedContestDetails.timelineData = timelineData;

  // Tabs
  // Job Description
  parsedContestDetails.tasks =
    response[JOB_DESCRIPTION_FORM_ID][0]["formData"][
      "rolesAndResponsibilities"
    ];
  parsedContestDetails.jobSkills =
    response[JOB_DESCRIPTION_FORM_ID][0]["formData"]["skills"];
  parsedContestDetails.background =
    response[JOB_DESCRIPTION_FORM_ID][0]["formData"]["background"];
  parsedContestDetails.jobDescription =
    response[JOB_DESCRIPTION_FORM_ID][0]["formData"]["fullJobDescription"];

  // // Overview
  // parsedContestDetails.aboutContest = contest["about_contest"];
  // parsedContestDetails.contestStats = getStats(
  //   contest["statistics"][0].stats,
  //   "contest_stats"
  // );
  // parsedContestDetails.contestMetrics = getStats(
  //   contest["contest_metrics"][0].stats,
  //   "contest_metrics"
  // );

  // About Employer
  parsedContestDetails.logo =
    response[EMPLOYER_FORM_ID][0]["formData"]["uploadEmployerLogo"][0];
  parsedContestDetails.companyName =
    response[EMPLOYER_FORM_ID][0]["formData"]["employerName"];

  // Partners
  parsedContestDetails.partners = contest["partners"]
    ? contest["partners"]
    : [];

  // Rewards
  const serviceFee = response[REWARD_FORM_ID][0]["formData"]["rewards"].map(
    (reward) => {
      return {
        Attribute: reward.jobSeeker,
        Value: reward.placement,
      };
    }
  );
  const rewardsDescription =
    response[REWARD_FORM_ID][0]["formData"]["rewardDescriptions"];
  // const consolationPrizes =
  //   contest["consolation_prizes"].length > 0
  //     ? contest["consolation_prizes"].map((reward: any) => {
  //         const rewards: any = [];
  //         if (reward["cash_prize"]) {
  //           rewards.push(`cash-${reward["cash_prize"]}`);
  //         }
  //         if (reward["certificate"] === "yes") {
  //           rewards.push("certificate");
  //         }
  //         if (reward["coupons"]["enter_name"]) {
  //           rewards.push("coupon");
  //         }
  //         if (reward["swag"].length > 0) {
  //           rewards.push(`swag@${reward["swag"].toString()}`);
  //         }
  //         return {
  //           Attribute: reward.jobseeker_leaving_period,
  //           Value: rewards,
  //         };
  //       })
  //     : [];

  // const overAllStatistics: any = {};
  // overAllStatistics.participants = `${Number(
  //   contest["overall_statistics"]["participants"]
  // ).toLocaleString("en-IN")}`;
  // overAllStatistics.consolationPrizes = `₹${Number(
  //   contest["overall_statistics"]["consolation_prizes"]
  // ).toLocaleString("en-IN")}`;
  // overAllStatistics.rewardsAmount = `₹${Number(
  //   contest["overall_statistics"]["rewards"]
  // ).toLocaleString("en-IN")}`;
  // overAllStatistics.candidatesPlaced = `${Number(
  //   contest["overall_statistics"]["candidates_placed"]
  // ).toLocaleString("en-IN")}`;
  // overAllStatistics.certificatesOffered = `${Number(
  //   contest["overall_statistics"]["certificates_offered"]
  // ).toLocaleString("en-IN")}`;

  parsedContestDetails.rewards = {
    serviceFee,
    rewardsDescription,
    // consolationPrizes,
    // overAllStatistics,
  };

  // FAQ
  parsedContestDetails.faq =
    response[FAQ_FORM_ID][0]["formData"]["addQuestionAndAnswer"];
  // // Terms & Conditions
  // parsedContestDetails.termsAndConditions = contest["copy_and_paste_tnc"];

  // parsedContestDetails.contestUrl = contest["contest_url"];

  return parsedContestDetails;
};

export default parse;
