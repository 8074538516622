export function getSessionStorage(key: string, defaultValue: any) {
  const stored = sessionStorage.getItem(key);

  if (!stored) {
    return defaultValue;
  }

  return stored;
}

export function setSessionStorage(key: string, value: string) {
  sessionStorage.setItem(key, value);
}

export function getLocalStorage(key: string, defaultValue: null) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return defaultValue;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);

    return null;
  }

  return item.value;
}

export function setLocalStorage(key: string, value: any, expiresIn?: number) {
  const sessionExpiry = process.env.REACT_APP_SESSION_EXPIRY;
  const now = new Date();
  let expiry = now.getTime() + Number(sessionExpiry) * 1000;
  if (expiresIn) {
    expiry = now.getTime() + expiresIn * 1000;
  }

  const item = {
    value,
    expiry,
  };

  localStorage.setItem(key, JSON.stringify(item));
}

export function resetLocalStorage(key: string) {
  localStorage.removeItem(key);
}
