import { useState } from "react";
import { useSelector } from "react-redux";
import Share from "../Share";
import Tooltip from "../Tooltip";
import starImage from "../../assets/star.svg";
import viewImage from "../../assets/view.svg";
import thumbsDownImage from "../../assets/thumbs-down.svg";
import thumbsDownSelectedImage from "../../assets/thumbs-down-selected.svg";
import bookmarkImage from "../../assets/bookmark.svg";
import bookmarkSelectedImage from "../../assets/bookmark-selected.svg";
import helpImage from "../../assets/help.svg";
import styles from "./styles.module.scss";
import { getLocalStorage } from "../../utils/storage-manager";

interface Props {
  id: string;
  iconsToShow: Array<string>;
  rating?: number;
  views?: number;
  iconStatus?: string;
}

const ContestIcons: React.FC<Props> = (props) => {
  const { id, iconsToShow = [], rating, views, iconStatus } = props;
  const token = getLocalStorage("token", null);
  const recruiterId = getLocalStorage("userId", null);

  const [previousState, setPreviousState] = useState(iconStatus);

  const urlToShare = `www.hiringhood.com/contest/${id}`;

  const actionUrl = `${process.env.REACT_APP_RECRUITER_PROFILE}/${recruiterId}/contests`;

  const BOOKMARKED = "bookmarked";
  const REJECTED = "rejected";

  const triggerAction = (payload) => {
    if (token) {
      fetch(actionUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(payload),
      }).catch((err) => {
        console.error(err);
      });
    }
  };

  const onRejected = () => {
    const payload = { contest_id: id, previous_status: previousState };
    if (previousState === REJECTED) {
      payload["status"] = "";
      setPreviousState("");
    } else {
      payload["status"] = REJECTED;
      setPreviousState(REJECTED);
    }
    triggerAction(payload);
  };

  const onBookmarked = () => {
    const payload = { contest_id: id, previous_status: previousState };
    if (previousState === BOOKMARKED) {
      payload["status"] = "";
      setPreviousState("");
    } else {
      payload["status"] = BOOKMARKED;
      setPreviousState(BOOKMARKED);
    }
    triggerAction(payload);
  };

  return (
    <div className={styles["contest-icons-conatiner"]}>
      <div className={styles["contest-icon-conatiner"]}>
        <img src={starImage} alt="Contest Rating" title="Contest Rating" />
        <p className={styles["contest-icons-text"]}>{rating}</p>
      </div>
      {iconsToShow.includes("visit") && (
        <div className={styles["contest-icon-conatiner"]}>
          <Tooltip
            title={
              <div>
                Total Views: {views ? views.toLocaleString("en-IN") : null}
              </div>
            }
          >
            <div className={styles["contest-icon-conatiner"]}>
              <img src={viewImage} alt="Views" />
              <p className={styles["contest-icons-text"]}>
                {views ? views.toLocaleString("en-IN") : null}
              </p>
            </div>
          </Tooltip>
        </div>
      )}
      {iconsToShow.includes("not-interested") && (
        <div
          className={`${styles["contest-icon-conatiner"]} ${styles["contest-icon-clickable"]}`}
        >
          <img
            src={
              previousState === "rejected"
                ? thumbsDownSelectedImage
                : thumbsDownImage
            }
            alt="Not Interested"
            title="Not Interested"
            onClick={() => {
              onRejected();
            }}
          />
        </div>
      )}
      {iconsToShow.includes("bookmarked") && (
        <div
          className={`${styles["contest-icon-conatiner"]}  ${styles["contest-icon-clickable"]}`}
        >
          <img
            src={
              previousState === "bookmarked"
                ? bookmarkSelectedImage
                : bookmarkImage
            }
            alt="Bookmark"
            title="Bookmark"
            onClick={() => {
              onBookmarked();
            }}
          />
        </div>
      )}
      {iconsToShow.includes("shares") && (
        <div className={styles["contest-icon-conatiner"]}>
          <Share url={urlToShare} />
        </div>
      )}
      {iconsToShow.includes("post-your-query") && (
        <div
          className={`${styles["contest-icon-conatiner"]}  ${styles["contest-icon-clickable"]}`}
        >
          <img src={helpImage} alt="Questions" title="Questions" />
        </div>
      )}
    </div>
  );
};

export default ContestIcons;
