import React, { FC, useEffect } from "react";
import { Grid, Button, 
  TextField, CircularProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  specialCharValidation,
  BILLING_DETAILS_FORM,
  SNACKBAR_ERROR_MSG,
  FREELANCE_RECRUITER,
  RECRUITMENT_COMPANY,
  ifscValidation,
  accountNumValidation,
  aadharNumValidation,
  panNumValidation,
  gstNumValidation,
  tanNumValidation,
  SUCCESS_KEY,
  WARNING_KEY,
  ERROR_KEY,
  BACK_BTN_TEXT,
  FORM_SUBMISSION_SUCCESS,
  NUMBER_ONLY_REGEX
} from "../../constants";
import { useAppSelector } from "../../services/StoreHooks";
import { getFormData, postFormData } from "../../services/FormDataService";
import { ThemeProvider } from '@mui/material/styles';
import {
  formLabelsTheme,
  Item,
  useStyles
} from '../../InternalStyles/BillingDetailsStyles';
import { 
  FormAttributes,
  BanksArray,
  CAPS_NUM_REGEX,
  SUBMIT_TEXT
} from "../../ConstantValues/BillingDetailsConstants";
import {
  AUTO_MARGIN, FULL_SIZE_GRID,
  HALF_SIZE_GRID, FULL_WIDTH_PERCENT, 
} from '../../InternalStyles/CommonStyleVariables';
import './BillingDetails.css';

const BillingDetailsForm: FC<any> = (props) => {
  const classes = useStyles();

  const [branch, setBranch] = React.useState("");
  const [ifscCode, setIfscCode] = React.useState("");
  const [aadharNumber, setAadharNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [tanNumber, setTanNumber] = React.useState("");
  const [gstNumber, setGstNumber] = React.useState("");
  const [accountHolderName, setAccountHolderName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [selectedBankName, setSelectedBankName] = React.useState("");
  const [gotData, setGotData] = React.useState(false);
  const [dataId, setdataId] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [errorState, setErrorState] = React.useState("");

  const userDataState = useAppSelector((state) => state.currentUser);
  const isCompany = userDataState.userData.userType === RECRUITMENT_COMPANY;

  useEffect(() => {
      const fillBill = async () => {
        const fillBilling = await getFormData(BILLING_DETAILS_FORM, userDataState.userId);
        if(fillBilling && fillBilling?.data?.data.length > 0) patchData(fillBilling?.data?.data[0].formData);
      }
      const fillResponse = props.billingDetails;
      if (fillResponse.id) {
        setGotData(true);
        patchData(fillResponse)
      }
      fillBill();
  }, [gotData]);

  const patchData = (patchObject: any) => {
    if (isCompany) {
      setTanNumber(patchObject.tanNumber);
      setGstNumber(patchObject.gstNumber);
    } else {
      setAadharNumber(patchObject.aadharNumber);
    }
    if(BanksArray.includes(patchObject.bankAccounts[0].bankName))
      setSelectedBankName(patchObject.bankAccounts[0].bankName);
    else {
      setSelectedBankName(BanksArray[BanksArray.length -1]);
      setBankName(patchObject.bankAccounts[0].bankName);
    }
    setIfscCode(patchObject.bankAccounts[0].ifscCode);
    setdataId(patchObject.id);
    setPanNumber(patchObject.panNumber);
    setBranch(patchObject.bankAccounts[0].branch);
    setAccountHolderName(patchObject.bankAccounts[0].accountHolderName);
    setAccountNumber(patchObject.bankAccounts[0].accountNumber);
  }

  const submitData = async () => {
    const errorInformed = submitDataValidation();
    if (errorInformed) {
      setErrorState(errorInformed);
      props.setDataMessage(`invalid ${errorInformed}`);
      props.setType(WARNING_KEY);
      props.setOpen(true);
      return;
    }
    setLoader(true);
    const formPayload = {
      formId: BILLING_DETAILS_FORM,
      formData: {
        partyType: userDataState.userData.userType
          ? RECRUITMENT_COMPANY
          : FREELANCE_RECRUITER,
        userId: userDataState.userId,
        partyIdentifier: {
          recruiterId: userDataState.userId,
          companyId: userDataState.userData.companyId,
        },
        aadharNumber,
        gstNumber,
        panNumber,
        tanNumber,
        bankAccounts: [
          {
            bankName,
            accountHolderName,
            accountNumber,
            branch,
            ifscCode,
          },
        ],
      },
    };
    const formPostResponse = await postFormData(
      dataId ? Object.assign({ ...formPayload, id: dataId }) : formPayload
    );
    if(formPostResponse && formPostResponse.data && formPostResponse.data.success) {
      props.setDataMessage(FORM_SUBMISSION_SUCCESS);
      props.setType(SUCCESS_KEY);
      props.setOpen(true);
      props.handleComplete();
      setLoader(false);
      props.handleNext();
    } else {
      props.setDataMessage(SNACKBAR_ERROR_MSG);
      props.setType(ERROR_KEY);
      props.setOpen(true);
      setLoader(false);
    }
  };

  const submitDataValidation = () => {
    if (isCompany && !gstNumValidation(gstNumber))
    return FormAttributes.gstNumber.stateValue;
    if (!isCompany && (!aadharNumber || !aadharNumValidation(aadharNumber))) return FormAttributes.aadharNumber.stateValue;
    if (!panNumber || !panNumValidation(panNumber)) return FormAttributes.panNumber.stateValue;
    if (isCompany &&(!tanNumber || !tanNumValidation(tanNumber)))
      return FormAttributes.tanNumber.stateValue;
    if (!accountHolderName) return FormAttributes.accountHolderName.stateValue;
    if (!bankName) return FormAttributes.bankName.stateValue;
    if (!accountNumValidation(accountNumber)) return FormAttributes.accountNumber.stateValue;
    if (!ifscCode || !ifscValidation(ifscCode)) return FormAttributes.ifscCode.stateValue;
  };

  return (
    <ThemeProvider theme={formLabelsTheme}>
        <div className="billing-form-padding">
          <Grid container spacing={1} mx="auto" maxWidth={407}>
            <Grid item xs={FULL_SIZE_GRID}>
              {isCompany ? (
                <Item>
                  <TextField
                    id={FormAttributes.gstNumber.id}
                    label={FormAttributes.gstNumber.label}
                    className={classes.inputField}
                    inputProps={{ maxLength: FormAttributes.gstNumber.maxLength }}
                    fullWidth
                    required
                    error={errorState === FormAttributes.gstNumber.stateValue ? true : false}
                    value={gstNumber}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z0-9\b]+$/;
                      if (!regex.test(e.target.value) && e.target.value !== '') return false;
                      setGstNumber(e.target.value);
                    }}
                  />
                </Item>
              ) : (
                <Item>
                  <TextField
                    id={FormAttributes.aadharNumber.id}
                    label={FormAttributes.aadharNumber.label}
                    className={classes.inputField}
                    inputProps={{ maxLength: FormAttributes.aadharNumber.maxLength }}
                    fullWidth
                    required
                    error={errorState === FormAttributes.aadharNumber.stateValue ? true : false}
                    value={aadharNumber}
                    onChange={(e) => {
                      const regex = NUMBER_ONLY_REGEX;
                      if (!regex.test(e.target.value) && e.target.value !== '') return false;
                      setAadharNumber(e.target.value);
                    }}
                  />
                </Item>
              )}
              <Item className="billing-item">
                  <TextField
                    id={FormAttributes.panNumber.id}
                    className={classes.inputField}
                    inputProps={{ maxLength: FormAttributes.panNumber.maxLength }}
                    fullWidth
                    required
                    value={panNumber}
                    label={`${
                      isCompany
                        ? "Company "
                        : ""
                    } ${FormAttributes.panNumber.label}`}
                    error={errorState === FormAttributes.panNumber.stateValue ? true : false}
                    onChange={(e) => {
                      const regex = CAPS_NUM_REGEX;
                      if (!regex.test(e.target.value) && e.target.value !== '') return false;
                      setPanNumber(e.target.value)
                    }}
                  />
              </Item>
              {isCompany ? (
                <Item>
                  <TextField
                    id={FormAttributes.tanNumber.id}
                    label={FormAttributes.tanNumber.label}
                    className={classes.inputField}
                    fullWidth
                    required
                    inputProps={{ maxLength: FormAttributes.tanNumber.maxLength }}
                    error={errorState === FormAttributes.tanNumber.stateValue ? true : false}
                    value={tanNumber}
                    onChange={(e) => {
                      const regex = CAPS_NUM_REGEX;
                      if (!regex.test(e.target.value) && e.target.value !== '') return false;
                      setTanNumber(e.target.value);
                    }}
                  />
                </Item>
              ) : null}
              <p className="billing-section-title">
                Bank Details
              </p>
              <Item className="billing-item">
                <FormControl sx={{ width: FULL_WIDTH_PERCENT, padding: 0, margin: 0 }}>
                  <InputLabel id="demo-select-small">{FormAttributes.bankName.label}<span className="asterisk-span"> *</span></InputLabel>
                  <Select
                    className="freelance-select"
                    value={selectedBankName}
                    error={errorState === FormAttributes.bankName.stateValue ? true : false}
                    label={FormAttributes.bankName.label}
                    onChange={(e) => {
                      setSelectedBankName(e.target.value);
                      if(e.target.value !== BanksArray[BanksArray.length -1])
                        setBankName(e.target.value);
                    }}
                  >
                    {
                      BanksArray.map(bank => (
                        <MenuItem key={bank} value={bank}>{bank}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Item>
              {
                selectedBankName === BanksArray[BanksArray.length -1] ? 
                <Item>
                  <TextField
                    id="other-bank-input"
                    label={FormAttributes.bankName.inputLabel}
                    className={classes.inputField}
                    inputProps={{ maxLength: FormAttributes.bankName.maxLength }}
                    fullWidth
                    required
                    error={errorState === FormAttributes.bankName.stateValue ? true : false}
                    value={bankName}
                    onChange={(e) => {
                      if (!specialCharValidation(e.target.value)) return false;
                      setBankName(e.target.value);
                    }}
                  />
                </Item>
                : null
              }
              <Item>
                <TextField
                  id={FormAttributes.accountHolderName.id}
                  label={FormAttributes.accountHolderName.label}
                  className={classes.inputField}
                  inputProps={{ maxLength: FormAttributes.accountHolderName.maxLength }}
                  fullWidth
                  required
                  error={errorState === FormAttributes.accountHolderName.stateValue ? true : false}
                  value={accountHolderName}
                  onChange={(e) => {
                    if (!specialCharValidation(e.target.value)) return false;
                    setAccountHolderName(e.target.value);
                  }}
                />
              </Item>
              <Item>
                <TextField
                  id={FormAttributes.accountNumber.id}
                  label={FormAttributes.accountNumber.label}
                  className={classes.inputField}
                  fullWidth
                  required
                  inputProps={{ maxLength: 18 }}
                  error={errorState === FormAttributes.accountNumber.stateValue ? true : false}
                  value={accountNumber}
                  onChange={(e) => {
                    const regex = CAPS_NUM_REGEX;
                    if (!regex.test(e.target.value) && e.target.value !== '') return false;
                    setAccountNumber(e.target.value);
                  }}
                />
              </Item>
              <Item>
                <TextField
                  id={FormAttributes.branch.id}
                  label={FormAttributes.branch.label}
                  className={classes.inputField}
                  inputProps={{ maxLength: FormAttributes.branch.maxLength }}
                  fullWidth
                  error={errorState === FormAttributes.branch.stateValue ? true : false}
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                />
              </Item>
              <Item>
                <TextField
                  id={FormAttributes.ifscCode.id}
                  label={FormAttributes.ifscCode.label}
                  className={classes.inputField}
                  fullWidth
                  required
                  inputProps={{ maxLength: 11 }}
                  error={errorState === FormAttributes.ifscCode.stateValue ? true : false}
                  value={ifscCode}
                  onChange={(e) => {
                    const regex = CAPS_NUM_REGEX;
                    if (!regex.test(e.target.value) && e.target.value !== '') return false;
                    setIfscCode(e.target.value);
                  }}
                />
              </Item>
            </Grid>
            {
              loader ? 
                  <div className="center-loader"> 
                      <CircularProgress /> 
                  </div> : 
                  <>
                    <Grid item xs={HALF_SIZE_GRID} className="billing-button-grid">
                        <Item>
                          <Button
                            variant="outlined"
                            className="outlined-radius-button"
                            onClick={(e) => props.handleBack()}
                            fullWidth
                          >
                            {BACK_BTN_TEXT}
                          </Button>
                        </Item>
                    </Grid>
                    <Grid item xs={HALF_SIZE_GRID} className="billing-button-grid">
                        <Item>
                          <Button
                            variant="contained"
                            onClick={submitData}
                            className="radius-button next-button"
                            fullWidth
                          >
                            {SUBMIT_TEXT}
                          </Button>
                        </Item>
                    </Grid>
                  </>
            }

          </Grid>
        </div>
    </ThemeProvider>
  );
};

export default BillingDetailsForm;
