export interface Contest {
  id: string;
  contestId: string;
}

export type ContestEvent = { type: "CONTEST_NAVIGATE"; data: Contest };

const initial: Contest = {
  id: "",
  contestId: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: Contest = initial, event: ContestEvent): Contest => {
  switch (event.type) {
    case "CONTEST_NAVIGATE":
      return {
        ...state,
        id: event.data.id,
        contestId: event.data.contestId,
      };
    default:
      return state;
  }
};
