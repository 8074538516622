import { useEffect } from "react";
import heroImage from "../../assets/about-us-image.svg";
import whoWeAre from "../../assets/who-we-are.svg";
import ourCommitment from "../../assets/our-commitment.svg";
import ProfileCard from "../../components/ProfileCard";
import CoreValues from "../../components/CoreValues";
import OurCommitment from "../../components/OurCommitment";
import TitleMultiColor from "../../components/TitleMultiColor";
import BookACall from "../../components/BookACall";
import WhatMakesUsDifferent from "../../components/WhatMakesUsDifferent";
import Subscribe from "../../components/Subscribe";
import logo from "../../assets/logo-white.svg";
import { team } from "../../utils/constants/site-data";
import { useAppDispatch } from "../../services/StoreHooks";
import "./styles.scss";

const AboutUs = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch({
      type: "SET_ACTIVE_LINK",
      data: {
        activeLink: "About Us",
      },
    });
  }, [dispatch]);

  return (
    <div className="wrapper about-us-container">
      <div className="about-us-wrapper">
        <div
          style={{
            backgroundImage: `url(${heroImage})`,
          }}
          className="about-us-hero-image"
        >
          <div className="about-us-glass-card">
            <img src={logo} alt="Logo" className="about-us-glass-card-image" />
            <p className="about-us-glass-card-text">
              Hiringhood is a first in class recruiters aggregation marketplace
              technology platform, powered by AI/ML for accelerated hiring.
            </p>
            <p className="about-us-glass-card-text">
              We are the first premium bounty hunting & crowd sourcing
              recruitment company.
            </p>
          </div>
        </div>
        <div className="about-us-who-we-are-container">
          <img
            src={whoWeAre}
            alt="Who We Are"
            className="about-us-who-we-are"
          />
          <div className="about-us-who-we-are-card">
            <TitleMultiColor
              labels={["Who", "We Are"]}
              colors={["#626880", "#4D6CD9"]}
            />
            <p className="about-us-who-we-are-card-text">
              Launched in 2019, Hiringhood is the leading online bounty hunter
              recruiter and freelancer network with a few hundred members across
              India. Recruiters come to Hiringhood to discover premium
              recruitment contests that need to be filled immediately.
            </p>
            <p className="about-us-who-we-are-card-text">
              Recruiters then source resumes to these contests and the best
              candidate when selected gets the bounty prize. Hiringhood makes
              sure that recruiters take home 80% of the bounty from the
              employer.
            </p>
          </div>
        </div>

        <div className="about-us-our-commitment-stats">
          <div
            style={{
              backgroundImage: `url(${ourCommitment})`,
            }}
            className="about-us-our-commitment-backgroung-image"
          >
            <div className="about-us-our-commitment-title">
              <TitleMultiColor
                labels={["Our Commitment", "To Recruiters"]}
                colors={["#38F4A4", "#FFFFFF"]}
              />
            </div>
            <OurCommitment />
          </div>
        </div>

        <div className="about-us-what-makes-us-different-title">
          <TitleMultiColor
            labels={["What Makes Us", "Different"]}
            colors={["#626880", "#4D6CD9"]}
          />
        </div>
        <div className="about-us-what-makes-us-different">
          <WhatMakesUsDifferent />
        </div>

        <div className="about-us-values-at-our-core">
          <CoreValues />
        </div>
        <p className="about-us-meet-the-team-text">
          Meet <span style={{ color: "#4D6CD9" }}>The Team </span>
        </p>
        <div className="about-us-meet-the-team-container">
          {team.map((member) => {
            return (
              <ProfileCard
                key={member.name}
                image={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}${member.image}`}
                title={member.name}
                role={member.role}
                description={member.description}
                linkedIn={member.linkedInUrl}
              />
            );
          })}
        </div>
        {/* <div className="about-us-blogs-container">
          <Blogs title="Latest Stories" type="stories" />
        </div> */}
        <div className="about-us-lets-chat">
          <BookACall />
        </div>
        <div className="about-us-subscribe-container">
          <Subscribe />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
