import { combineReducers } from "redux";
import currentUser from "./userState";
import adminContest from "./adminContest";
import menuState from "./menuState";
import contestState from "./contestState";
import contestsState from "./contestsState";

export default combineReducers({
  currentUser,
  adminContest,
  menuState,
  contestState,
  contestsState,
});
