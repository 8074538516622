import Card from "../Card";
import CountdownTimer from "../CountdownTimer";
import "./styles.css";

interface Props {
  jobPostedDuration: string;
  endDate: string;
}

const ContestTimer: React.FC<Props> = (props) => {
  const { jobPostedDuration, endDate } = props;

  return (
    <Card>
      <div className="contest-timer-container">
        <div className="contest-timer-label-container">
          <p className="contest-timer-resume-submission-label">
            Time left for resume submission
          </p>
          <p className="contest-timer-job-duration-label">
            Posted: {jobPostedDuration}
          </p>
        </div>
        <CountdownTimer endDate={endDate} />
      </div>
    </Card>
  );
};

export default ContestTimer;
