import employer from "../../assets/employer.png";
import rewards from "../../assets/rewards.png";
import users from "../../assets/users.svg";
import experience from "../../assets/experience.svg";
import wantedImage from "../../assets/wanted.svg";
import "./styles.css";

const JSProfileContestDetails = (props) => {
  const { contestDetails } = props;
  return (
    <div className="js-profile-contest-details">
      <div className="js-profile-contest-details-section-1">
        <img src={wantedImage} alt="Wanted" />
        <p className="js-profile-contest-details-type">
          {contestDetails.employmentType}
        </p>
        <p className="js-profile-contest-details-section-1-text">
          Bounty:{" "}
          <span className="js-profile-contest-details-section-1-text-value">
            {contestDetails.cashReward}
          </span>
        </p>
        <p className="js-profile-contest-details-section-1-text">
          Location:{" "}
          <span className="js-profile-contest-details-section-1-text-value">
            {contestDetails.locations}
          </span>
        </p>
        <p className="js-profile-contest-details-section-1-text">
          Max. Notice Period:
          <span className="js-profile-contest-details-section-1-text-value">
            {contestDetails.noticePeriod}
          </span>
        </p>
        <p className="js-profile-contest-details-section-1-text">
          Contest Status:
          <span className="js-profile-contest-details-section-1-text-value">
            Active
          </span>
        </p>
      </div>
      <div className="js-profile-contest-details-section-2">
        <p className="js-profile-contest-details-contest-name">
          {contestDetails.jobTitle}
        </p>
        <div className="js-profile-contest-details-icons-container">
          <div className="js-profile-contest-details-icon-container">
            <img src={employer} alt="Employer" />
            <p className="js-profile-contest-details-icon-text">
              {contestDetails.companyName}
            </p>
          </div>
          <div className="js-profile-contest-details-icon-container">
            <img src={rewards} alt="Cash" />
            <p className="js-profile-contest-details-icon-text">
              {contestDetails.ctc}
            </p>
          </div>
        </div>
        <div className="js-profile-contest-details-icons-container">
          <div className="js-profile-contest-details-icon-container">
            <img src={users} alt="Positions" />
            <p className="js-profile-contest-details-icon-text">
              {contestDetails.positions}
            </p>
          </div>
          <div className="js-profile-contest-details-icon-container">
            <img src={experience} alt="Experience" />
            <p className="js-profile-contest-details-icon-text">
              {contestDetails.experience}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JSProfileContestDetails;
