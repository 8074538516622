export interface menuState {
  activeLink: string;
}

export type MenuEvent = { type: "SET_ACTIVE_LINK"; data: menuState };

const initial: menuState = {
  activeLink: "Home",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: menuState = initial, event: MenuEvent): menuState => {
  switch (event.type) {
    case "SET_ACTIVE_LINK":
      return {
        ...state,
        activeLink: event.data.activeLink,
      };
    default:
      return state;
  }
};
