import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Card from "../Card";
import Button from "../Button";
// import { setActiveLink } from "../../redux/actions/menu";
import notFoundImage from "../../assets/not-found.svg";
import "./styles.css";
import { Link } from "react-router-dom";
import { HOME } from "../../utils/constants/routes";

const Error = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(setActiveLink("Error"));
  }, [dispatch]);

  return (
    <div className="error-wrapper">
      <Card>
        <div className="error-container">
          <div>
            <p className="error-title">404 error</p>
            <p className="error-description">Page not found!</p>
            <Link to={HOME}>
              <Button label="Go to home" className="error-button" />
            </Link>
          </div>
          <img src={notFoundImage} alt="Not Found" />
        </div>
      </Card>
    </div>
  );
};

export default Error;
