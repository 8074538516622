import "./styles.css";
import Table from "../Table";
import participantsImg from "../../assets/participants.svg";
import candidatesImg from "../../assets/candidates.svg";
import rewardsImg from "../../assets/rewards.svg";
import prizesImg from "../../assets/prizes.svg";
import certificatesImg from "../../assets/certificates.svg";
import tShirt from "../../assets/t-shirt.svg";
import certificate from "../../assets/certificate.svg";
import coupon from "../../assets/coupon.svg";
import cash from "../../assets/cash.svg";
import separator from "../../assets/separator.svg";

const getIconsForReward = (item, isLastItem) => {
  if (item.includes("swag")) {
    return (
      <>
        <img src={tShirt} alt="Swag" title={item.split("@")[1]} />
        {!isLastItem && <img src={separator} alt="Swag" />}
      </>
    );
  } else if (item === "certificate") {
    return (
      <>
        <img src={certificate} alt="Certificate" title="Certificate" />
        {!isLastItem && <img src={separator} alt="Certificate" />}
      </>
    );
  } else if (item === "coupon") {
    return (
      <>
        <img src={coupon} alt="Discount Coupon" title="Discount Coupon" />
        {!isLastItem && <img src={separator} alt="Discount Coupon" />}
      </>
    );
  } else if (item.includes("cash")) {
    return (
      <>
        <img
          src={cash}
          alt="Cash Prize"
          title={`₹${Number(item.split("-")[1]).toLocaleString("en-IN")}`}
        />
        {!isLastItem && (
          <img
            src={separator}
            alt={`₹${Number(item.split("-")[1]).toLocaleString("en-IN")}`}
          />
        )}
      </>
    );
  }
};

const renderStats = (image, label, value) => {
  return (
    <div className="rewards-stat-container">
      <div className="rewards-stat-image-background">
        <img src={image} alt={label} />
      </div>
      <p className="rewards-stat-value">{value}</p>
      <p className="rewards-stat-text">{label}</p>
    </div>
  );
};

const Rewards = (props) => {
  const { rewards } = props;
  // const { overAllStatistics } = rewards;
  // const {
  //   participants,
  //   consolationPrizes,
  //   rewardsAmount,
  //   candidatesPlaced,
  //   certificatesOffered,
  // } = overAllStatistics;

  const serviceFeeTableHeaders = [
    "Job Seeker Joining Period (From Offer Rolled Out Date)",
    "Placement Fee (On Job Seeker Offered CTC)",
  ];
  const serviceFeeData: any = rewards.serviceFee;

  const consolationPrizesTableHeaders = [
    "Job Seeker Leaving Period",
    "Rewards",
  ];
  const consolationPrizesData: any =
    rewards.consolationPrizes &&
    rewards.consolationPrizes.map((prize) => {
      return {
        Attribute: prize.Attribute,
        Value: prize.Value.map((reward, index) =>
          getIconsForReward(reward, index === prize.Value.length - 1)
        ),
      };
    });

  return (
    <div className="rewards-container">
      {rewards.serviceFee && rewards.serviceFee.length > 0 && (
        <div className="service-fees-section">
          <label className="service-fees-title">Service Fee Table</label>
          <div className="service-fees-table">
            <Table headers={serviceFeeTableHeaders} data={serviceFeeData} />
          </div>
        </div>
      )}
      {consolationPrizesData && consolationPrizesData.length > 0 && (
        <div className="service-fees-section consolation-prizes">
          <label className="service-fees-title">Consolation Prizes</label>
          <div className="rewards-desc-text">
            *Consolation prizes paid to Top-5 recruiters who made offers but,
            the job seeker did not join as per the offered joining date.
            However, this is applicable only when at least 1 successful
            placement is done in this contest by any recruiter against each
            position.
          </div>
          <div className="service-fees-table">
            <Table
              headers={consolationPrizesTableHeaders}
              data={consolationPrizesData}
            />
          </div>
        </div>
      )}
      {rewards.rewardsDescription && (
        <div className="rewards-desc-section">
          <text className="rewards-desc-title">Rewards description</text>
          <div
            className="rewards-desc-text"
            dangerouslySetInnerHTML={{
              __html: rewards.rewardsDescription,
            }}
          />
        </div>
      )}
      {/* <div className="rewards-stats-container">
        {renderStats(participantsImg, "Participants", participants)}
        {renderStats(prizesImg, "Consolation Prizes", consolationPrizes)}
        {renderStats(rewardsImg, "Rewards", rewardsAmount)}
        {renderStats(candidatesImg, "Candidates Placed", candidatesPlaced)}
        {renderStats(
          certificatesImg,
          "Certificates Offered",
          certificatesOffered
        )}
      </div> */}
    </div>
  );
};

export default Rewards;
