import { useEffect } from "react";
import { useLocation, Router, withRouter, useHistory } from "react-router-dom";
import OnboardBase from "./pages/OnboardBase";
import Signup from "./pages/Signup/Signup";
import JSProfile from "./pages/JSProfile";
import { Route } from "react-router-dom";
import tawkTo from "tawkto-react";
import TagManager from "react-gtm-module";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Contest from "./pages/Contest";
import {
  HOME,
  CONTEST_DETAILS,
  ABOUT_US,
  JS_PROFILE_UPLOAD,
  SIGN_UP,
  ONBOARD,
  CONTESTS,
} from "./utils/constants/routes";
import Contests from "./pages/Contests";

const tawkToPropertyId = process.env.REACT_APP_TAWK_TO_PROP_ID;
const tawkToKey = process.env.REACT_APP_TAWK_TO_KEY;

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

function App() {
  // useEffect(() => {
  //   tawkTo(tawkToPropertyId, tawkToKey);
  //   TagManager.initialize(tagManagerArgs);
  // }, []);

  const history = useHistory();

  return (
    <Router history={history}>
      <Route exact path={SIGN_UP} component={Signup} />
      <Route exact path={CONTEST_DETAILS} component={Contest} />
      <Route exact path={CONTESTS} component={Contests} />
      <Route exact path={ABOUT_US} component={AboutUs} />
      <Route exact path={HOME} component={Home} />
      <Route exact path={JS_PROFILE_UPLOAD} component={JSProfile} />
      <Route exact path={ONBOARD} component={OnboardBase} />
    </Router>
  );
}

export default withRouter(App);
