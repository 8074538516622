import { useState, useMemo, useEffect } from "react";
import ContestCard from "../ContestCard";
import ContestListCard from "../ContestListCard";
import ContestFilters from "../ContestFilters";
import Pagination from "../Pagination";
import gridViewImage from "../../assets/grid-view.png";
import gridViewActiveImage from "../../assets/grid-view-active.svg";
import listViewImage from "../../assets/list-view.png";
import listViewActiveImage from "../../assets/list-view-active.svg";
import SelectValidator from "../SelectValidator";
import "./styles.css";
import { Drawer, Button } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

interface Props {
  contestsData: any;
  numberOfContestsPerPage?: number;
  onReset?: any;
}

const Contests: React.FC<Props> = (props) => {
  const { contestsData, numberOfContestsPerPage = 12, onReset } = props;
  const { contests = [] } = contestsData;
  const [currentPage, setCurrentPage] = useState(1);
  const [gridView, setGridView] = useState(true);
  const [openFilters, setOpenFilters] = useState(false);

  let [sortedContests, setSortedContests] = useState(
    contests && contests.length > 0 ? 
    [
        ...contests.sort(function (contest1, contest2) {
          return contest1.postedDuration - contest2.postedDuration;
        }),
    ]
    : []
    );

  const [isSorting, setIsSorting] = useState(true);

  const sortData = async () => {
    const data = contests && contests.length > 0
    ? [
        ...contests.sort(function (contest1, contest2) {
          return contest1.postedDuration - contest2.postedDuration;
        }),
      ]
    : [];

    console.log("sorted contests raw   **** " + JSON.stringify(data.length));

    setSortedContests([...data]);
    console.log("sorted contests  assigned   **** " + JSON.stringify(sortedContests.length));

    setIsSorting(false);
  };

  useEffect(() => {
    sortData();
  }, []);
 
  const [activeSort, setActiveSort] = useState("Newest");

  const pageData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * numberOfContestsPerPage;
    const lastPageIndex = firstPageIndex + numberOfContestsPerPage;
    return sortedContests.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, sortedContests, numberOfContestsPerPage]);

  console.log("page data length " + JSON.stringify(pageData.length));
  const sortOptions = [
    "Newest",
    "Oldest",
    "High Rewards",
    "Low Rewards",
    "High Budget",
    "Low Budget",
    "Number of Positions",
    "High Quota",
    "Low Quota",
    "Days Left",
  ];

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const toggleDrawer = () => {
    setOpenFilters((prevState) => !prevState);
  };

  const handleSort = (sortedContests, value) => {
    let updatedContests = sortedContests;
    if (value === "Newest") {
      updatedContests = updatedContests.sort(function (contest1, contest2) {
        return contest1.postedDuration - contest2.postedDuration;
      });
    }
    if (value === "Oldest") {
      updatedContests.sort(function (contest1, contest2) {
        return contest2.postedDuration - contest1.postedDuration;
      });
    }
    if (value === "High Rewards") {
      updatedContests.sort(function (contest1, contest2) {
        return contest2.reward - contest1.reward;
      });
    }
    if (value === "Low Rewards") {
      updatedContests.sort(function (contest1, contest2) {
        return contest1.reward - contest2.reward;
      });
    }
    if (value === "High Budget") {
      updatedContests.sort(function (contest1, contest2) {
        return contest2.maxBudget - contest1.maxBudget;
      });
    }
    if (value === "Low Budget") {
      updatedContests.sort(function (contest1, contest2) {
        return contest1.minBudget - contest2.minBudget;
      });
    }
    if (value === "Number of Positions") {
      updatedContests.sort(function (contest1, contest2) {
        return contest2.numberOfPositions - contest1.numberOfPositions;
      });
    }
    if (value === "High Quota") {
      updatedContests.sort(function (contest1, contest2) {
        return contest2.totalQuota - contest1.totalQuota;
      });
    }
    if (value === "Low Quota") {
      updatedContests.sort(function (contest1, contest2) {
        return contest1.totalQuota - contest2.totalQuota;
      });
    }
    if (value === "Days Left") {
      updatedContests.sort(function (contest1, contest2) {
        return contest1.daysLeft - contest2.daysLeft;
      });
    }
    setSortedContests([...updatedContests.slice()]);
  };

  const onFiltersChanged = (list) => {
    handleSort(list, activeSort);
  };

  return (
    <div className="contests-container">
      <div className="contests-title-container">
        <p className="contests-title">Hiring Contests</p>
        <div className="contests-sort-container">
          <img
            src={gridView ? gridViewActiveImage : gridViewImage}
            alt="Grid View"
            onClick={() => {
              setGridView(true);
            }}
            className="contests-icon hidden-mobile"
          />
          <img
            src={gridView ? listViewImage : listViewActiveImage}
            alt="List View"
            onClick={() => {
              setGridView(false);
            }}
            className="contests-icon contests-list-view-icon hidden-mobile"
          />
          <div className="contests-sort">
            <SelectValidator
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              register={() => {}}
              label="Sort by"
              list={sortOptions}
              value={sortOptions[0]}
              callback={(e) => {
                setActiveSort(e.target.value);
                handleSort(sortedContests, e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="filters-button">
        <Button
          variant="outlined"
          endIcon={<FilterAltOutlinedIcon />}
          onClick={toggleDrawer}
        >
          Filters
        </Button>
      </div>
      <div className="contests-wrapper-container">
        <Drawer anchor={"left"} open={openFilters} onClose={toggleDrawer}>
          <ContestFilters
            contestsData={contestsData}
            onFiltersChanged={onFiltersChanged}
            onReset={onReset}
          />
        </Drawer>
        <div className="contests-filters-container">
          <ContestFilters
            contestsData={contestsData}
            onFiltersChanged={onFiltersChanged}
            onReset={onReset}
          />
        </div>
        { !isSorting && pageData && pageData.length > 0  && (
          <>
            <div>
              {gridView && (
                <div className="contests-contests-container">
                  {pageData &&
                    pageData.length > 0 &&
                    pageData.map((contest: any, index: number) => {
                      return (
                        <ContestCard contestDetails={contest} key={index} />
                      );
                    })}
                </div>
              )}
            </div>
            <div>
              {!gridView &&
                pageData.map((contest: any, index: number) => {
                  return (
                    <div className="contests-list-card" key={index}>
                      <ContestListCard contestDetails={contest} key={index} />
                    </div>
                  );
                })}
            </div>
          </>
        )}
        <div>
          {!isSorting && pageData && pageData.length === 0 && (
            <p className="contests-no-contests">
              There are currently no active contests
            </p>
          )}
        </div>
        <div>
          {isSorting && (
            <p className="contests-no-contests">
              We are still sorting the data
            </p>
          )}
        </div>
      </div>
      <Pagination
        className="contests-pagination-container"
        currentPage={currentPage}
        totalCount={sortedContests.length}
        pageSize={numberOfContestsPerPage}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default Contests;
