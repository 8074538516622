import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import JobSeekerBase from "job-seeker-flow/dist/pages/JobSeekerBase";
import JSProfileContestDetails from "../../components/JSProfileContestDetails";
import { useAppDispatch, useAppSelector } from "../../services/StoreHooks";
import { CONTESTS_API_URL } from "../../utils/urls";
import parse from "../../utils/parsers/contest";

const JobSeeker = () => {
  const location = useLocation();
  const state: any = location.state;
  const { id, contestId } = state;
  const dispatch = useAppDispatch();

  const url = `${CONTESTS_API_URL}/${id}`;

  const [error, setError] = useState("");

  useEffect(() => {
    dispatch({
      type: "SET_ACTIVE_LINK",
      data: { activeLink: "Hiring Contests" },
    });
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: "SET_CONTEST",
          data: { ...parse(response.data.data.content[0]), loading: false },
        });
      })
      .catch((err) => {
        setError(err);
      });
  }, [dispatch, url]);

  useEffect(() => {
    dispatch({
      type: "CONTEST_NAVIGATE",
      data: {
        id,
        contestId,
      },
    });
  }, [contestId, dispatch, id]);

  const contestDetails = useAppSelector(
    (state: any) => state.contestState.contestData
  );

  const loading = useAppSelector(
    (state: any) => state.contestState.contestData.loading
  );

  return (
    <>
      {loading && <CircularProgress className="loading" />}
      <div>
        <JSProfileContestDetails contestDetails={contestDetails} />
        <div className="js-profile-js-flow">
          <JobSeekerBase contestId={contestId} id={id} />
        </div>
      </div>
    </>
  );
};

export default JobSeeker;
