import styles from "./style.module.scss";

interface Props {
  logo?: string;
  companyName: string;
}

const AboutEmployer: React.FC<Props> = (props) => {
  const { logo, companyName } = props;

  return (
    <div className={styles["about-employer-wrapper"]}>
      <div className={styles["about-employer-container"]}>
        <div className={styles["about-employer-company-details"]}>
          <div className={styles["about-employer-company-logo"]}>
            <img src={logo} alt="Logo" />
          </div>
          <div className={styles["about-employer-company-name-container"]}>
            <div>
              <p className={styles["about-employer-company-name"]}>
                {companyName}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutEmployer;
