import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./style.module.scss";
import logo from "../../assets/logo-color.svg";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import telegram from "../../assets/telegram.svg";
import linkedin from "../../assets/linkedin.svg";
import { footer as footerData } from "../../utils/constants/site-data";
import { COPYRIGHT } from "../../utils/constants/index";
import Contact from "../Contact";
import {
  getLocalStorage,
  resetLocalStorage,
} from "../../utils/storage-manager";
import { LOGIN } from "../../utils/constants/routes";

const Footer = () => {
  const navigate = useHistory();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const onClose = () => {
    setOpen(false);
  };

  const isAuthenticated = true;

  const handleMenuClick = (
    menu:
      | { label: string; link: string; isDisabled?: undefined }
      | { label: string; link?: undefined; isDisabled?: undefined }
      | { label: string; isDisabled: boolean; link?: undefined }
  ) => {
    if (menu.link) {
      navigate.push(menu.link);
    }

    if (menu.label === "Contact Us") {
      setOpen(true);
    }

    if (menu.label === "Sitemap") {
      window.open(`${process.env.REACT_APP_BASE_URL}/sitemap.xml`);
    }

    if (menu.label === "Login") {
      if (isAuthenticated) {
        // dispatch(setAuthenticationStatus(false));
        resetLocalStorage("token");
        resetLocalStorage("user");
        resetLocalStorage("userId");
        resetLocalStorage("userRole");
        resetLocalStorage("userStage");
        resetLocalStorage("inviteSource");
        resetLocalStorage("profilePic");
      } else {
        // history.push(LOGIN);
        navigate.push(LOGIN);
      }
    }

    if (menu.label === "Terms & Conditions") {
      window.open(
        `${process.env.REACT_APP_PUBLIC_BUCKET_URL}legal-docs/Terms+of+Use_Hiringhood.pdf`
      );
    }

    if (menu.label === "Privacy Policy") {
      window.open(
        `${process.env.REACT_APP_PUBLIC_BUCKET_URL}legal-docs/Privacy+Policy_HiringHood.pdf`
      );
    }
  };

  return (
    <div className={styles["footer"]}>
      <div className={styles["footer-wrapper"]}>
        <div className={styles["logo"]}>
          <img src={logo} alt="Hiringhood Logo"></img>
          <p>
            Hiringhood is a first-in-class recruiters aggregation marketplace
            technology platform, powered by AI/ML for accelerated hiring. <br />
            <br /> We are the first premium bounty hunting & crowd sourcing
            recruitment company.
          </p>
        </div>

        {footerData.map((item) => {
          const content = Array.isArray(item.content) ? item.content : [];
          return (
            <div className={styles[item.title.toLowerCase()]} key={item.title}>
              <div>{item.title}</div>
              <ul>
                <div className={styles["menu-item-container"]}>
                  {content.map((menu, i) => (
                    <li
                      key={i}
                      className={
                        menu.isDisabled ? styles["footer-item-disabled"] : ""
                      }
                      onClick={() => handleMenuClick(menu)}
                    >
                      {menu.label === "Login" && isAuthenticated
                        ? "Logout"
                        : menu.label}
                    </li>
                  ))}
                </div>
              </ul>
            </div>
          );
        })}

        <div className={styles["support"]}>
          <div>Support</div>
          <ul>
            <div>
              <li>
                3rd Floor, Gutenberg IT Park, Kalajyothi Rd, Masjid Banda, Sai
                Pruthvi Enclave, Kondapur, Hyderabad, Telangana - 500084
              </li>
            </div>
          </ul>
          <div className={styles["social-icons"]}>
            <div className={styles["social-icon"]}>
              <img src={facebook} alt="Facebook" />
            </div>
            <div className={styles["social-icon"]}>
              <img src={twitter} alt="Twitter" />
            </div>
            <div className={styles["social-icon"]}>
              <img src={telegram} alt="Telegram" />
            </div>
            <div className={styles["social-icon"]}>
              <img src={linkedin} alt="Linkedin" />
            </div>
          </div>
        </div>
      </div>
      <hr className={styles["separator"]} />
      <div className={styles["copyright-container"]}>
        <p className={styles["copyright"]}>{COPYRIGHT}</p>
      </div>
      {open && (
        <Contact
          onClose={onClose}
          showComapnyAndDesignation={false}
          showMessage
        />
      )}
    </div>
  );
};

export default Footer;
