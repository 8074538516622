import React from "react";
import "./styles.scss";

interface Props {
  id: string;
  phone: string;
  phoneExt: string;
  onPhoneChange: React.ChangeEventHandler<HTMLInputElement>;
  onExtChange: React.ChangeEventHandler<HTMLInputElement>;
  label: string;
  register?: any;
  errorMessage?: string;
  disabled?: boolean;
}

const PhoneNumberValidator = (props: Props): JSX.Element => {
  const {
    id,
    phone,
    phoneExt,
    onPhoneChange,
    onExtChange,
    label,
    register,
    errorMessage,
    disabled = false,
  } = props;

  return (
    <div className="textfield-wrapper">
      <label htmlFor={id} className={phone ? "active-phone" : "phone"}>
        {label}
      </label>
      <div className="div-phone-number">
        <input
          id={id + "Ext"}
          name={id + "Ext"}
          {...register}
          value={phoneExt}
          className={"input-text phone-prefix-input"}
          onChange={onExtChange}
          placeholder="+91"
          disabled={disabled}
        />
        <input
          id={id}
          name={id}
          {...register}
          value={phone}
          className={"input-text phone-number-input"}
          onChange={onPhoneChange}
          disabled={disabled}
        />
      </div>
      <p className={"invalid-feedback"}>{errorMessage}</p>
    </div>
  );
};

export default PhoneNumberValidator;
