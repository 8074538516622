import "./styles.scss";
import GreenLine from "../../assets/green-line.svg";
const WhyHiringhoodCard = (props: any) => {
  const { title, description } = props;
  return (
    <div className="why-hh-card">
      <div className="why-hh-title-container">
        <label className="why-hh-card-title">{title}</label>
        <img src={GreenLine} />
      </div>
      <label className="why-hh-card-desc">{description}</label>
    </div>
  );
};
export default WhyHiringhoodCard;
