import AvatarFemale from "../../assets/avatar-female.png";
import AvatarMale from "../../assets/avatar-male.png";
import AvatarGroup from "../../assets/avatar-group.png";

export const COPYRIGHT = `© ${new Date()
  .getFullYear()
  .toString()} Hiringhood, All Rights Reserved`;

//Company Details
export const COUNTRIES = ["India"];
export const EDIT_PROFILE_STEPS = [
  "Company",
  "Background",
  "Billing",
  "Add Team",
];
export const EDIT_FR_PROFILE_STEPS = [
  "Personal Details",
  "Background Details",
  "Billing Details",
];

export const RECRUITMENT_COMPANY_STAGE_NAMES = [
  "Company Details",
  "Company Background Details",
  "Company Billing Details",
  "Company Recruiter Details",
];
export const FREELANCE_RECRUITER_STAGE_NAMES = [
  "Freelance Recruiter Personal Details",
  "Freelance Recruiter Background Details",
  "Freelance Recruiter Billing Details",
];

export const PHONE_REGEX = /^\d{10}$/;
export const PHONE_EXT_REGEX = /^\+?\d{2}$/;
export const LANDLINE_REGEX = /^\+?\d{10,12}$/;
export const PINCODE_REGEX = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
export const PAN_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const GST_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
export const IFSC_REGEX = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const TAN_REGEX = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
export const ACCOUNT_NUMBER_REGEX = /^\d{10,15}$/;
export const AADHAAR_CARD_REGEX = /^[2-9]{1}[0-9]{3}\s?[0-9]{4}\s?[0-9]{4}$/;

//Onboard constants
export const DEFAULT_ONBOARD = "company-recruiter";
export const RECRUITMENT_COMPANY = "company-recruiter";
export const FREELANCER = "freelance-recruiter";

//user role - URL endpoint mapping
export const USER_URL_MAPPING = {
  "company-recruiter": "company",
  "freelance-recruiter": "freelance",
};

//File Upload constants
export const MAX_FILE_SIZE = 5242880; //5Mb

//company bancground
export const SLIDER_MARKS = [
  {
    value: 1,
    label: "01",
  },
  {
    value: 3,
    label: "03",
  },
  {
    value: 5,
    label: "05",
  },
  {
    value: 7,
    label: "07",
  },
  {
    value: 10,
    label: "10+ yrs",
  },
];
export const SURVEY_LIST = [
  "Reference",
  "Word of mouth",
  "Facebook",
  "LinkedIn",
  "Twitter",
  "Google Search",
  "Instagram",
  "Pintrest",
  "Our Website",
  "Other",
];

export const ROLES = [
  "Accounting & Finance",
  "Administrative",
  "Customer Service",
  "Education",
  "Sales & Business Development",
  "Marketing, PR & Advertising",
  "Management",
  "Human Resources",
  "Legal",
  "Engineering",
  "Information Technology",
  "Production & Manufacturing",
  "Healthcare",
  "Research & Development",
  "Other",
  "Non IT Consultant",
  "Technician & Mechanic",
];

export const REQUIREMENTS = [
  "Niche",
  "Super Niche",
  "Executive",
  "Basic",
  "Other",
];

export const LEVELS = [
  "Fresher, Trainee, Entry Level",
  "Junior Level",
  "Mid Level",
  "Senior Level",
  "Lead Level",
  "Manager Level",
  "Director Level",
  "Executive Level",
];

export const SKILL_SETS = [
  "Marketing",
  "Communication",
  "Administrative",
  "Finanace",
  "IT",
  "Management",
  "Software Development",
  "Trainer",
];

export const JOB_REQUISITIONS = [
  {
    label: "Full Time",
    value: "fulltime",
    key: 1,
  },
  {
    label: "Contract",
    value: "contract",
    key: 2,
  },
  {
    label: "RPO",
    value: "rpo",
    key: 3,
  },
  {
    label: "Other",
    value: "other",
    key: 4,
  },
];

export const JOB_REQUISITION_LIST = ["fulltime", "contract", "rpo"];
export const JOB_REQUISITION_OTHER = "other";

export const INDUSTRIES = [
  "Agriculture, Forestry, Fishing and Mining",
  "Automotive & Aviation",
  "Banking, Financial Services & Insurance",
  "Consulting & Professional Services",
  "Construction",
  "Consumer Goods, Food, Retail & Wholesale",
  "Energy, Oil, Gas & Utilities",
  "Government, Non-profit & Education",
  "Healthcare",
  "Staffing, Recruiting and Human resources",
  "Software and Technology",
  "Manufacturing (Discrete & Process)",
  "Travel, Transportation & Logistics",
  "Telecom, Media & Entertainment",
  "Life Sciences (Bio-tech, Medical Devices & Pharmaceuticals)",
  "Other",
];

export const COMPANY_TYPES = [
  "Sole Proprietorship",
  "Partnership ",
  "Limited Liability Partnership",
  "Private Limited Companies ",
  "Public Limited Companies ",
  "One-Person Companies",
];

export const PLACEMENTS_COUNT = [
  "Less than 5",
  "5 to 10",
  "11 to 25",
  "26 to 50",
  "51 to 75",
  "Above 75",
];
export const RECRUITERS_COUNT = [
  "Less than 5",
  "5 to 10",
  "11 to 25",
  "26 to 50",
  "51 to 75",
  "Above 75",
];

export const RECRUITER_TYPES = ["Freelance Recruiter", "Recruitment Company"];

export const GENDER = ["Female", "Male", "Others"];

export const EDUCATION = [
  "Bachelor of commerce",
  "Bachelor of science",
  "B.Tech/B.E",
  "MBA",
  "MCom",
  "Others",
];

export const PROFILE_AVATAR_OPTIONS = [
  {
    name: "avatar-female.png",
    dataURL: AvatarFemale,
  },
  {
    name: "avatar-male.png",
    dataURL: AvatarMale,
  },
];
