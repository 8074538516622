export interface contestsState {
  contestsData: any;
}

export type ContestsEvent = { type: "SET_CONTESTS"; data: contestsState };

const initial: contestsState = {
  contestsData: { loading: true },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: contestsState = initial,
  event: ContestsEvent
): contestsState => {
  switch (event.type) {
    case "SET_CONTESTS":
      return {
        ...state,
        contestsData: event.data,
      };
    default:
      return state;
  }
};
