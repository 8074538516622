import "./styles.scss";

type Style = Record<string, unknown>;

/* variant types: 
primary   : Used general as default button
secondary : Used in cases to highlight the button on different background color. 
outlined: : Has a white background with default color border outlined. 
textonly  : Has no outline or background. Its text that acts as button.
option    : Set of buttons act as checkbox i.e. stays in selected state until clicked again.*/

interface Props {
  label: string;
  variant?: string;
  className?: string;
  disabled?: boolean;
  style?: Style;
  onClick?: (arg0: any) => void;
  selected?: boolean;
}

const Button = (props: Props): JSX.Element => {
  const {
    label,
    variant,
    className = "",
    disabled = false,
    style,
    onClick,
    selected = false,
  } = props;

  const variantMap = new Map();
  variantMap.set("primary", "button-primary");
  variantMap.set("secondary", "button-secondary");
  variantMap.set("outlined", "button-outlined");
  variantMap.set("textonly", "button-textonly");
  variantMap.set("option", "button-option");

  const getVariantClassName = (selected: boolean) => {
    let variantClassname = variantMap.get(variant);
    variantClassname =
      selected === true ? variantClassname + "-selected" : variantClassname;
    return variantClassname ? variantClassname : "button-primary";
  };

  return (
    <button
      value={label}
      className={`button ${getVariantClassName(selected)} ${className}`}
      disabled={disabled}
      style={style}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
