import { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import HeroImageContainer from "../../components/HeroImageContainer";
import Contests from "../../components/Contests";
import Error from "../../components/Error";
import { useAppDispatch, useAppSelector } from "../../services/StoreHooks";
import "./styles.css";

import axios from "axios";
import { CONTESTS_API_URL } from "../../utils/urls";
import parse from "../../utils/parsers/contests-parser";

const ContestsPage = () => {

  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    axios
      .get(CONTESTS_API_URL)
      .then(response => {
        dispatch({
            type: "SET_CONTESTS",
            data: { ...parse(response), loading: false },
          });
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  }, [dispatch, CONTESTS_API_URL]);

  const contestsData = useAppSelector(
    (state: any) => state.contestsState.contestsData
  );

  const loading = useAppSelector(
    (state: any) => state.contestsState.contestsData.loading
  );

  useEffect(() => {
    dispatch({
      type: "SET_ACTIVE_LINK",
      data: {
        activeLink: "Hiring Contests",
      },
    });
  }, [dispatch]);

  const heroImages = [
    {
      "title": "ATMECS",
      "content": "ATMECS Hiring for Hyderabad location for Immediate Joiners.",
      "image": "https://hiringhood-images.s3.ap-south-1.amazonaws.com/3438-ATMECS-1.png"
    },
    {
      "title": "OKoders",
      "content": "OKoders Hiring for Hyderabad location for Immediate Joiners.",
      "image": "https://hiringhood-images.s3.ap-south-1.amazonaws.com/3436-OKODERS.png"
    },
    {
      "title": "ACL Digital",
      "content": "ACL Hiring for Hyderabad location for Immediate Joiners.",
      "image": "https://hiringhood-images.s3.ap-south-1.amazonaws.com/3432-ACL-1.png"
    }
  ]

  const [rerender, setRerender] = useState(false);

  function handleReset() {
    setRerender(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (rerender) {
      setRerender(false);
    }
    // dispatch(getBanners());
  }, [dispatch, rerender]);

    return (
    <div>
      {loading && <CircularProgress className="loading" />}
      {!loading && !error && (
        <div>
          <div className="contests-hero-image-container">
            <HeroImageContainer heroImages={heroImages} />
          </div>
          <div className="contests-contest-cards-container">
            <Contests contestsData={contestsData} onReset={handleReset} />
          </div>
        </div>
      )}
      {!loading && error && <Error />}
    </div>
  );
};

export default ContestsPage;
