import { useState } from "react";
import TitleMultiColor from "../TitleMultiColor";
import FAQ from "../FAQ";
import { homePageFAQ } from "../../utils/constants/site-data";
import styles from "./style.module.scss";
import React from "react";

const HomePageFAQ = () => {
  const labels = ["Frequently Asked", "Questions"];
  const colors = ["#626880", "#4D6CD9"];

  const [faq, setFAQ] = useState(homePageFAQ[0].faq);
  const [selectedSections, setSelectedSections] = useState([true]);

  const renderFAQSection = (image: any, selectedImage: any, title: any, itemIndex: any) => {
    return (
      <div key={title}
        className={styles["home-page-faq-section"]}
        onClick={() => {
          const updatedSections = homePageFAQ.map((item, index) => {
            if (index === itemIndex) {
              return true;
            }
            return false;
          });
          setSelectedSections([...updatedSections]);
          setFAQ(homePageFAQ[itemIndex].faq);
        }}
      >
        <img
          src={selectedSections[itemIndex] ? selectedImage : image}
          alt={title}
        />
        <p className={styles["home-page-faq-section-title"]}>{title}</p>
      </div>
    );
  };

  return (
    <div className={styles["home-page-faq-container"]}>
      <div style={{ textAlign: "center" }}>
        <TitleMultiColor labels={labels} colors={colors} />
      </div>
      <div className={styles["home-page-faq-sections"]}>
        {homePageFAQ.map((item, index) => {
          return renderFAQSection(
            item.image,
            item.selectedImage,
            item.title,
            index
          );
        })}
      </div>
      <div className={styles["home-page-faqs"]}>
        <FAQ hideTitle faq={faq} />
      </div>
    </div>
  );
};

export default HomePageFAQ;
