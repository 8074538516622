import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import KeycloakService from "./services/KeycloakService";
import HttpService from "./services/HttpService";
import { Provider } from "react-redux";
import StoreService from "./services/StoreService";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { BrowserRouter } from "react-router-dom";

const locArr = JSON.stringify(window.location.href).slice(0, -1).split("/");

const unauthenticatedRoutes = ["signup", "aboutUs", "contest", "contests"];

let authenticationRequired = true;
if (
  locArr.findIndex((data) => {
    return unauthenticatedRoutes.includes(data);
  }) !== -1 ||
  locArr[3] === ""
) {
  authenticationRequired = false;
}
const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={StoreService}>
          <div className="app">
            <Header />
            <App />
            <Footer />
          </div>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
if (authenticationRequired) {
  KeycloakService.initKeycloak(renderApp);
  HttpService.configure();
} else {
  renderApp();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
