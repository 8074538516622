import { useState } from "react";
import Card from "../Card";
import FAQ from "../FAQ";
import Overview from "../Overview";
import TermsAndConditions from "../TermsAndConditions";
import JobDescription from "../JobDescription";
import Partners from "../Partners";
import Rewards from "../Rewards";
import AboutEmployer from "../AboutEmployer";
import ContestMetrics from "../ContestMetrics";
import { Box, Menu, MenuItem, Button } from "@mui/material";
import "./styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface Props {
  aboutContest: string;
  domains: string;
  jobDescription: string;
  rewards: any;
  partners: any;
  faq: any;
  termsAndConditions: string;
  contestStats: any;
  contestMetrics: any;
  companyName: string;
  logo?: string;
  tasks: string;
  background: string;
  skills: string;
}

const ContestTabs: React.FC<Props> = (props) => {
  const {
    aboutContest,
    domains,
    jobDescription,
    rewards,
    partners,
    faq,
    termsAndConditions,
    contestStats,
    contestMetrics,
    logo,
    companyName,
    tasks,
    skills,
    background,
  } = props;

  // const stats = {
  //   contestStatsData: {
  //     profilesSubmitted: contestStats[0],
  //     profilesShortListed: contestStats[1] ? contestStats[1] : 0,
  //     l1Done: contestStats[2] ? contestStats[2] : 0,
  //     l2Done: contestStats[3] ? contestStats[3] : 0,
  //     hrDone: contestStats[4] ? contestStats[4] : 0,
  //     offerOut: contestStats[5] ? contestStats[5] : 0,
  //     joined: contestStats[6] ? contestStats[6] : 0,
  //   },
  //   myStatsData: {
  //     profilesSubmitted: 0,
  //     profilesShortListed: 0,
  //     l1Done: 0,
  //     l2Done: 0,
  //     hrDone: 0,
  //     offerOut: 0,
  //     joined: 0,
  //   },
  // };

  const tabs = [
    "Job Description",
    // "Overview",
    "About Employer",
    "Partners",
    "Rewards",
    "FAQs",
    "Terms & Conditions",
  ];

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeTabName, setActiveTabName] = useState("Job Description");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Card>
        <div className="contest-tabs-container">
          <p className={`contest-tabs-text`} onClick={handleClick}>
            {activeTabName}{" "}
            <KeyboardArrowDownIcon className="contest-tabs-img" />
          </p>

          <Menu
            // sx={{
            //   display: { lg: "none" },
            // }}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {tabs.map((tab, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setActiveTabName(tab);
                  setActiveTabIndex(index);
                  handleClose();
                }}
              >
                {tab}
              </MenuItem>
            ))}
          </Menu>

          {tabs.map((tab, index) => {
            return (
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "none",
                    sm: "none",
                  },
                }}
                key={index}
                className={`contest-tabs-tab ${
                  activeTabIndex === index ? "contest-tabs-active-tab" : ""
                }`}
              >
                <p
                  className={`contest-tabs-text ${
                    activeTabIndex === index ? "contest-tabs-active-text" : ""
                  }`}
                  onClick={() => {
                    setActiveTabIndex(index);
                  }}
                  tabIndex={0}
                  onKeyDown={(event) => {
                    event.keyCode === 13 && setActiveTabIndex(index);
                  }}
                >
                  {tab}
                </p>
              </Box>
            );
          })}
        </div>
        {activeTabIndex === 0 && (
          <div className="contest-tabs-job-description-container">
            <JobDescription
              description={jobDescription}
              tasks={tasks}
              skills={skills}
              background={background}
            />
          </div>
        )}
        {/* {activeTabIndex === 1 && (
          <div className="contest-tabs-overview-container">
            <Overview aboutContest={aboutContest} domains={domains} />
          </div>
        )} */}
        {activeTabIndex === 1 && (
          <div>
            <AboutEmployer logo={logo} companyName={companyName} />
          </div>
        )}
        {activeTabIndex === 2 && (
          <div className="contest-tabs-partners-container">
            <Partners partners={partners} />
          </div>
        )}
        {activeTabIndex === 4 && (
          <div className="contest-tabs-faq-container">
            <FAQ faq={faq} />
          </div>
        )}
        {activeTabIndex === 5 && (
          <div className="contest-tabs-terms-container">
            <TermsAndConditions termsAndConditions={termsAndConditions} />
          </div>
        )}
      </Card>
      {/* {activeTabIndex === 1 && (
        <div className="contest-tabs-stats-container">
          <ContestStats stats={stats} />
        </div>
      )}
      {activeTabIndex === 1 && (
        <div className="contest-tabs-stats-container">
          <ContestMetrics
            positions={contestMetrics[0] ? contestMetrics[0] : 0}
            resumes={contestMetrics[1] ? contestMetrics[1] : 0}
            participants={contestMetrics[2] ? contestMetrics[2] : 0}
            ratings={contestMetrics[3] ? contestMetrics[3] : 0}
          />
        </div>
      )} */}
      {activeTabIndex === 3 && <Rewards rewards={rewards} />}
    </div>
  );
};

export default ContestTabs;
