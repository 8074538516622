const parse = (response: any) => {
  const allContestDetails = [];
  const allLocations = [];
  const allEmploymentTypes = [];
  const allJobRoles = [];
  const allEmployers = [];

  const contests = response.data.data.content.map((item: any) => {  
    const contest = item["formData"];
    const postedDate = new Date(contest["startDate"]).getTime();
    const endDate = new Date(contest["startDate"]).getTime();
    //  moment(
    //   contest["rounds"]["countdown"]["timer"],
    //   "DD/MM/YYYY hh:mm a"
    // )
    //   .toDate()
    //   .getTime();

    const currentDate = new Date().getTime();

    const postedDuration = Math.ceil(
      (currentDate - postedDate) / (1000 * 24 * 60 * 60)
    );

    const endDuration = Math.floor(
      (endDate - currentDate) / (1000 * 24 * 60 * 60)
    );

    const parsedContestDetails: any = {};

    parsedContestDetails.id = item["id"];

    parsedContestDetails.tag = "invite-only"// contest["sticker_customization"];

    parsedContestDetails.badge = "most-wanted"// contest["mostWanted"];

    parsedContestDetails.contestCreatedDuration = `${
      postedDuration === 0 ? "Today" : `${postedDuration}d ago`
    }`;

    parsedContestDetails.postedDuration = postedDuration;

    parsedContestDetails.employmentType = contest["cardTaglines"];

    parsedContestDetails.jobTitle = contest["contestTitle"];

    parsedContestDetails.company = item["991289466351534080"][0]["formData"]["employerName"];

    parsedContestDetails.cashReward = `₹${contest["bounty"].toLocaleString(
      "en-IN"
    )}`;

    parsedContestDetails.reward = contest["bounty"];
    const experience =  {
      "": null,
      "from": contest["experienceFrom"],
      "to": contest["experienceTo"]
    }

    parsedContestDetails.experience = experience
      ? `${contest["experienceFrom"]} to ${contest["experienceTo"]} yrs`
      : null;

    parsedContestDetails.totalQuota = contest["totalApplicationLimitPerContest"];

    parsedContestDetails.daysLeft = endDuration < 0 ? 0 : endDuration;

    parsedContestDetails.positions =
      contest["numberOfPositions"] === 1
        ? `${contest["numberOfPositions"]} Position`
        : `${contest["numberOfPositions"]} Positions`;

    parsedContestDetails.numberOfPositions = contest["numberOfPositions"];

    const budgetUnit =
      contest["denomination"] === "thousands" ? "K PA" : "L PA";
    parsedContestDetails.ctc = `${contest["budgetCtcFrom"]}${budgetUnit} to ${contest["budgetCtcTo"]}${budgetUnit}`;

    const multiplicationFactor =
      contest["denomination"] === "thousands" ? 1000 : 100000;

    parsedContestDetails.minBudget =
      Number(contest["budgetCtcFrom"]) * multiplicationFactor;
    parsedContestDetails.maxBudget =
      Number(contest["budgetCtcTo"]) * multiplicationFactor;

    parsedContestDetails.locations = contest["locations"].join(", ");

    parsedContestDetails.filterLocations = contest["locations"];

    parsedContestDetails.filterExperience = contest["experience"]
      ? [
          Number(contest["experienceFrom"]),
          Number(contest["experienceTo"]),
        ]
      : [0, 0];

    parsedContestDetails.filterEmploymentType = [
      contest["cardTaglines"],
    ];

    parsedContestDetails.filterJobRole = [contest["contestTitle"]];

    parsedContestDetails.filterEmployer = [parsedContestDetails.company];

    allContestDetails.push(...[parsedContestDetails]);
    allLocations.push(...parsedContestDetails.filterLocations);
    allEmploymentTypes.push(...parsedContestDetails.filterEmploymentType);
    allJobRoles.push(...parsedContestDetails.filterJobRole);
    allEmployers.push(...parsedContestDetails.filterEmployer);

    return parsedContestDetails;
  });

  const parsedResponse = {
    contests,
    allLocations: [...Array.from(new Set(allLocations))],
    allEmploymentTypes: [...Array.from(new Set(allEmploymentTypes))],
    allJobRoles: [...Array.from(new Set(allJobRoles))],
    allEmployers: [...Array.from(new Set(allEmployers))]
  };

  return parsedResponse;
};

export default parse;