export interface contestState {
  contestData: any;
}

export type ContestEvent = { type: "SET_CONTEST"; data: contestState };

const initial: contestState = {
  contestData: { loading: true },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: contestState = initial,
  event: ContestEvent
): contestState => {
  switch (event.type) {
    case "SET_CONTEST":
      return {
        ...state,
        contestData: event.data,
      };
    default:
      return state;
  }
};
