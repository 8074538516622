import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import { CONTESTS_API_URL } from "../../utils/urls";
import parse from "../../utils/parsers/contest";
import ContestDetailCard from "../../components/ContestDetailCard";
import Timeline from "../../components/Timeline";
import ContestTimer from "../../components/ContestTimer";
import ContestQuota from "../../components/ContestQuota";
import ContestTabs from "../../components/ContestTabs";
import { useAppDispatch, useAppSelector } from "../../services/StoreHooks";
import "./styles.css";

const Contest = () => {
  const params: any = useParams();
  const [error, setError] = useState("");

  const { id } = params;

  const url = `${CONTESTS_API_URL}/${id}`;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_ACTIVE_LINK",
      data: { activeLink: "Hiring Contests" },
    });
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: "SET_CONTEST",
          data: { ...parse(response.data.data.content[0]), loading: false },
        });
      })
      .catch((err) => {
        setError(err);
      });
  }, [dispatch, url]);

  const contestDetails = useAppSelector(
    (state: any) => state.contestState.contestData
  );

  const loading = useAppSelector(
    (state: any) => state.contestState.contestData.loading
  );

  return (
    <div>
      {loading && <CircularProgress className="loading" />}
      {!loading && !error && (
        <div className="contest-wrapper">
          <div className="contest-container">
            <div className="contest-details">
              <ContestDetailCard contestDetails={contestDetails} />
              <div className="contest-timer-quota-container">
                <div className="contest-timer-wrapper">
                  <ContestTimer
                    endDate={contestDetails.endDate}
                    jobPostedDuration={contestDetails.jobPostedDuration}
                  />
                </div>
                <div className="contest-quota-wrapper">
                  <ContestQuota
                    bonus={contestDetails.bonus}
                    profilesMatched={contestDetails.profilesMatched}
                    quota={contestDetails.quota}
                  />
                </div>
              </div>
            </div>
            <div className="contest-timeline-container">
              <Timeline timelineData={contestDetails.timelineData} />
            </div>
          </div>
          <ContestTabs
            aboutContest={contestDetails.aboutContest}
            domains={contestDetails.domains}
            jobDescription={contestDetails.jobDescription}
            rewards={contestDetails.rewards}
            partners={contestDetails.partners}
            faq={contestDetails.faq}
            termsAndConditions={contestDetails.termsAndConditions}
            contestStats={contestDetails.contestStats}
            contestMetrics={contestDetails.contestMetrics}
            companyName={contestDetails.companyName}
            tasks={contestDetails.tasks}
            background={contestDetails.background}
            skills={contestDetails.jobSkills}
          />
        </div>
      )}
      {/* {!loading && error && <Error />} */}
    </div>
  );
};

export default Contest;
